import { Component, OnInit, Output, EventEmitter, ViewChild, Input, OnDestroy } from '@angular/core';
import { AgendaAmbulatoriaService } from 'src/app/services/agenda-ambulatoria.service';
import { FormControl } from '@angular/forms'
import { map, startWith, debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { Observable, Observer } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { ActivatedRoute } from '@angular/router';
import { OrderPipe } from 'ngx-order-pipe';
import { BLOQUEO_LABORATORIO_CLINICO, ENV, TooltipsAreas } from 'src/environments/environment';
import gtag, { install } from 'ga-gtag';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material';
import { EncuestasComponent } from 'src/app/shared/components/modals/encuestas/encuestas.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.scss']
})
export class BusquedaComponent implements OnInit, OnDestroy {

  public expanded = false;
  public areas: any = []
  public especialidades: any = [];
  public realEspecialidades: any = [];
  public profesionales: any = [];
  public servicios: any = [];

  public filterEspecialidades: Observable<any[]>;
  public filterCentrosAtencion: Observable<any[]>;
  public filterProfesionales: Observable<any[]>;;
  public filterServicios: Observable<any[]>;;

  public centrosAtencion: any = [];
  public areaSelected: any;
  public profesionalSelected: any;
  public especialidadSelected: any;
  public centroAtencionSelected: any;
  public servicioSelected: any;

  public tipoConsulta: string = "especialidad";

  public profesionalCtrl = new FormControl();
  public especialidadCtrl = new FormControl();
  public centroAtencionCtrl = new FormControl();
  public servicioCtrl = new FormControl();

  public readQuery: boolean = false;
  public hasQueryParams = false;
  public loadedProf: boolean = false;
  public loadedEsp: boolean = false;
  public loadedCen: boolean = false;
  public loadedServ: boolean = false;

  public loadingEspecialidades: boolean = false;
  public loadingProfesionales: boolean = false;

  public loadedByUrlEspecialidades: boolean = false;
  public loadedByUrlProfesionales: boolean = false;
  public needLoadInitEspecialidades: boolean = false;
  public needLoadInitProfesionales: boolean = false;
  public aplicaMedioContraste = false;
  public tooltipsAreas = TooltipsAreas;

  public requiereOrden = false;
  public requiereOrdenObligatorio = false; 
  public cantidadMaximaOrdenes = ENV.cantidadMaximaOrdenes;
  public habilitarMensajeDerivacion = ENV.habilitarMensajeDerivacion;
  public idLaboratorio = ENV.idAreaLaboratorio;
  public idBancoSangre = ENV.donacionBancoDeSangre.idArea;
  public porDerivacionUrl = false;
  public tokenRequiereOrden = "";
  public mensajeBloqueoLaboratorioDomicilio = BLOQUEO_LABORATORIO_CLINICO.mensaje;
  public mostrarMensajeBloqueoLaboratorioDomicilio = BLOQUEO_LABORATORIO_CLINICO.mensajeActivo;
  @Input() isSaludIntegral;

  public datosPaciente: any = {
    tipoDocumento: 'RUN',
    documento: null,
    documentoFormateado: null,
    idPaciente: null
  }

  public datosImagenes = {
    aplicaMedioContraste: false,
    archivo: null,
    requierePresupuesto: false,
    idEncuesta: null,
    listadoArchivos: [],
    requiereOrden: false,
    requiereOrdenObligatorio: false,
    tokenRequiereOrden: ''
  }

  public datasUpload = [];
  public bloquearRadiologia = false;
  public respuestasEncuestas = [];

  @Output() public emitReadQuery: EventEmitter<boolean> = new EventEmitter();
  @Output() public emitBusqueda: EventEmitter<any> = new EventEmitter();
  @Input() public etapaActual: number;

  @ViewChild('triggerEspecialidad', { read: MatAutocompleteTrigger, static: false }) triggerEspecialidad: MatAutocompleteTrigger;
  @ViewChild('triggerCentros', { read: MatAutocompleteTrigger, static: false }) triggerCentros: MatAutocompleteTrigger;
  @ViewChild('triggerServicios', { read: MatAutocompleteTrigger, static: false }) triggerServicios: MatAutocompleteTrigger;

  constructor(
    public agendaService: AgendaAmbulatoriaService,
    public utils: UtilsService,
    public aRouter: ActivatedRoute,
    public orderPipe: OrderPipe,
    public dialog: MatDialog,
    public http: HttpClient
  ) {
  }

  ngOnInit() {
    this.getAreas();
    this.evaluarMarcaDerivacion();
    this.utils.nuevaHora.subscribe(r => {
      this.respuestasEncuestas = [];
      this.clearSelection('profesional');
      this.cambiarTipoBusqueda('especialidad');
      this.datosPaciente = {
        tipoDocumento: 'RUN',
        documento: null,
        documentoFormateado: null,
        idPaciente: null
      }

      this.datosImagenes = {
        aplicaMedioContraste: false,
        archivo: null,
        requierePresupuesto: false,
        idEncuesta: null,
        listadoArchivos: [],
        requiereOrden: false,
        requiereOrdenObligatorio: false,
        tokenRequiereOrden: ""
      }

      this.datasUpload = [];
      this.requiereOrden = false;
      this.requiereOrdenObligatorio = false;
      this.tokenRequiereOrden = ""
    });

  }

  ngOnDestroy() {

  }

  getParamsArea() {
    return new Promise((resolve, reject) => {
      this.aRouter.params.subscribe(params => {
        resolve(params);
      })
    })
  }

  setIdentificacion(params) {
    if (params.rut && params.tipoDocumento) {
      this.datosPaciente = {
        tipoDocumento: params.tipoDocumento,
        documento: params.rut,
        documentoFormateado: this.utils.formatRut(params.rut)
      }
    }
  }

  getAreas() {
    this.agendaService.getAreas().subscribe(res => {

      if (res['areas'] && res['areas'].length > 0) {
        this.areas = res['areas'];

        this.setDataQueryParams().then(async params => {
          let qp = params;
          this.setIdentificacion(params);
          this.areaSelected = {};
          let paramArea = await this.getParamsArea();

          res['areas'].forEach((val, key) => {
            let setParamArea = false;
            if (paramArea && paramArea['area'] && this.utils.slugify(val['nombre'], "-") === this.utils.slugify(paramArea['area'], "-")) {
              this.areaSelected = val;
              setParamArea = true;
            }

            if (
              (
                (val['nombre'].toLowerCase() == 'consultas' && !qp['area']) ||
                (qp['area'] && qp['area'].toLowerCase() == val['id'].toString().toLowerCase())
              ) && !setParamArea
            ) {
              this.areaSelected = val;
            }

          })

          if (!this.areaSelected.id) {
            res['areas'].forEach((val, key) => {
              if (val['nombre'].toLowerCase() == 'consultas') {
                this.areaSelected = val;
              }
            })
            this.emitterReadQuery(true)
          }

          this.clearSelection('profesional');

          if (qp['tipo'] && qp['tipo'].toLowerCase() == 'profesional') {
            this.getProfesionales();
            this.especialidadCtrl.disable();
            this.centroAtencionCtrl.disable();
          } else {
            this.especialidadCtrl.enable();
            this.servicioCtrl.disable();
            this.centroAtencionCtrl.disable();
            this.getEspecialidades('especialidad');
          }

        })

      } else {
        this.emitterReadQuery(true)
      }
    })
  }

  async evaluarMarcaDerivacion(){
    setTimeout(async ()=> {
      sessionStorage.removeItem("aplicaMarcaDerivacion");
      const params: any = await this.setDataQueryParams();
      const inParams = Object.keys(params);
      const parametrosAceptados = ['area', 'tipo', 'servicio', 'centro'];
      const aplicaMarca = parametrosAceptados.every( item => inParams.includes(item));
      sessionStorage.setItem("aplicaMarcaDerivacion", JSON.stringify(aplicaMarca));
    },500);
  }

  getProfesionales() {

    this.setDataQueryParams().then(params => {

      let qp = params;

      this.tipoConsulta = 'profesional';
      this.loadedProf = false;

      let idProfesional = (!this.loadedByUrlProfesionales) ? qp['profesional'] : null;
      this.loadedByUrlProfesionales = true;

      if (idProfesional) {
        this.needLoadInitProfesionales = true;
      }

      this.agendaService.getProfesionales(this.areaSelected['id'], null, idProfesional).subscribe(res => {

        this.clearSelection('profesional')

        this.profesionalCtrl.reset();

        let matchProfesional = null;

        if (res['profesionales'] && res['profesionales'].length > 0) {
          res['profesionales'].forEach((val, key) => {

            res['profesionales'][key]['detalle'] = val['nombreProfesional'];
            if (qp['profesional'] && qp['profesional'].toLowerCase() == val['idProfesional'].toString().toLowerCase()) {
              matchProfesional = val;
            }
          })

          this.profesionales = this.orderPipe.transform(res['profesionales'], 'detalle');

          if (matchProfesional) {
            this.profesionalCtrl.patchValue(matchProfesional);
            this.profesionalSelection(matchProfesional);
          } else {
            this.emitterReadQuery(true)
          }

        } else {
          this.profesionales = [];
        }

        this.valueChangeProfesional();

      })

    })


  }

  valueChangeProfesional() {


    this.profesionalCtrl.valueChanges.pipe(
      debounceTime(500),
      tap(() => {
        this.loadingProfesionales = true;
      }),
      switchMap(value => {

        if (!value || value['idProfesional'] || value == "" || value.length < 3) {
          return Observable.create((observer: Observer<any>) => {
            if (value == "" || value.length < 3) {
              observer.next([]);
            } else {
              observer.next([])
            }
            observer.complete();
          });
        }
        return this.agendaService.getProfesionales(this.areaSelected['id'], value)
      })
    )
      .subscribe(data => {
        if (data['profesionales']) {
          data['profesionales'].forEach((val, key) => {
            data['profesionales'][key]['detalle'] = val['nombreProfesional'];
          })
          this.filterProfesionales = data['profesionales'];
        } else {
          this.filterProfesionales = null;
        }
        this.loadingProfesionales = false;

      }, () => {
        this.loadingProfesionales = false;
      });

    this.loadedProf = true;

  }

  distinctEspecialidades(data) {

    let especialidades = [];

    if (data && data['especialidades'] && data['especialidades'].length > 0) {
      data['especialidades'].forEach((val, key) => {
        especialidades = especialidades.concat(val);
      });
    }

    return { especialidadesPorServicio: especialidades };
  }

  getEspecialidades(tipo: string) {

    this.setDataQueryParams().then(params => {
      let qp = params;

      this.tipoConsulta = tipo;
      this.loadedEsp = false;

      let observer: any;
      if (tipo == 'profesional') {
        observer = this.agendaService.getEspecialidadesByProfesional(this.profesionalSelected['idProfesional'], this.areaSelected['id']);
      } else {

        let idServicio = null;

        if (!this.loadedByUrlEspecialidades) {
          idServicio = (this.tipoConsulta == 'especialidad') ? qp['servicio'] : qp['especialidad'];
        }

        if (idServicio) {
          this.needLoadInitEspecialidades = true;
        }
        observer = this.agendaService.getEspecialidadesByGeneric(this.areaSelected['id'], null, idServicio);
        this.clearSelection('especialidad');

      }

      this.loadedByUrlEspecialidades = true;
      this.loadingEspecialidades = true;

      observer.subscribe(res => {

        this.especialidadCtrl.reset();
        let matEspecialidad = null;

        if (tipo == 'especialidad') {
          res = this.distinctEspecialidades(res);
          res['especialidadesPorServicio'] = this.ocultarEspecialidades(res['especialidadesPorServicio'], 'idEspecialidad');
        } else {
          res['especialidadesPorServicio'] = this.ocultarServicios(res['especialidadesPorServicio'], 'idServicio');
        }

        if (res['especialidadesPorServicio'] && res['especialidadesPorServicio'].length > 0) {
          res['especialidadesPorServicio'].forEach((val, key) => {

            if (tipo == 'especialidad') {
              res['especialidadesPorServicio'][key]['detalle'] = val['nombreEspecialidad'];
            } else {
              res['especialidadesPorServicio'][key]['detalle'] = val['nombreEspecialidad'] + " - " + val['nombreServicio'];
            }

            if ((tipo == 'profesional' && qp['especialidad'] && qp['especialidad'].toLowerCase() == val['idServicio'].toString().toLowerCase()) ||
              (tipo == 'especialidad' && qp['especialidad'] && qp['especialidad'].toLowerCase() == val['idEspecialidad'].toString().toLowerCase())) {
              matEspecialidad = val;
            }

          });

          this.especialidades = this.orderPipe.transform(res['especialidadesPorServicio'], 'detalle');
          this.filterEspecialidades = this.orderPipe.transform(res['especialidadesPorServicio'], 'detalle');

          if (matEspecialidad) {
            this.especialidadCtrl.patchValue(matEspecialidad);
            this.especialidadSelection(matEspecialidad, tipo);
          } else {

            if (tipo == 'profesional') {
              if (res['especialidadesPorServicio'].length == 1) {
                this.especialidadCtrl.patchValue(res['especialidadesPorServicio'][0]);
                this.especialidadSelection(res['especialidadesPorServicio'][0]);
              }
            }

            this.emitterReadQuery(true)
          }


        } else {
          this.especialidades = [];
          this.emitterReadQuery(true)
        }

        this.filterEspecialidades = this.especialidadCtrl.valueChanges.pipe(
          startWith<string | any>(''),
          map(value => typeof value === 'string' ? value : value.detalle),
          map(nombreFiltro => nombreFiltro ? this.filterAutocomplete(nombreFiltro, 'especialidades') : this.especialidades.slice()),
        );

        this.loadedEsp = true;
        this.loadingEspecialidades = false;

      }, () => {
        this.loadingEspecialidades = false;
      })

    })
  }

  filterAutocomplete(nombreFiltro: string, tipoAutoComplete: string): any[] {

    const filterValue = nombreFiltro.toLowerCase();
    switch (tipoAutoComplete) {

      case 'profesionales':
        return this.profesionales.filter(option => {
          let detalle = option.detalle.toLowerCase();
          return ((detalle.indexOf(filterValue) >= 0))
        })
        break;

      case 'especialidades':
        return this.especialidades.filter(option => {
          let detalle = option.detalle.toLowerCase();
          return ((detalle.indexOf(filterValue) >= 0))
        })
        break;

      case 'centros':
        return this.centrosAtencion.filter(option => {
          let detalle = option.detalle.toLowerCase();
          return ((detalle.indexOf(filterValue) >= 0))
        })
        break;

      case 'servicios':
        return this.servicios.filter(option => {
          let detalle = option.detalle.toLowerCase();
          return ((detalle.indexOf(filterValue) >= 0))
        })
        break;

      default:
        return [];
        break;
    }
  }

  displayTextAutocomplete(obj?: any): string | undefined {
    return obj ? obj.detalle : undefined;
  }

  changeAreas(event) {

    this.respuestasEncuestas = [];
    sessionStorage.removeItem("aplicaMarcaDerivacion");

    this.porDerivacionUrl = false;
    this.requiereOrden = false;
    this.areaSelected = event.value;
    this.clearSelection('profesional');
    this.tipoConsulta = 'especialidad';
    this.filterEspecialidades = null;
    this.filterServicios = null;
    this.filterCentrosAtencion = null;
    this.getEspecialidades('especialidad');
    this.expanded = false;

    this.datosImagenes = {
      aplicaMedioContraste: false,
      archivo: null,
      requierePresupuesto: false,
      idEncuesta: null,
      listadoArchivos: [],
      requiereOrden: false,
      requiereOrdenObligatorio: false,
      tokenRequiereOrden: ""
    }

    this.datasUpload = [];
    this.requiereOrden = false;
    this.requiereOrdenObligatorio = false;
    this.bloquearRadiologia = ENV.bloquearAreaRadiologia && this.areaSelected.id === 'RIS_IMAGENES';

    gtag('event', 'Filtro de Búsqueda', { 'event_category': 'Seleccionar Área', 'event_label': this.areaSelected.nombre, 'value': '0' });

    this.agendaService.crearMarca({
      dni: null,
      dni_type: null,
      element: 'RADIO - SELECCIONAR ÁREA',
      value: this.areaSelected.nombre.toUpperCase(),
      detail: 'PACIENTE SELECCIONA UN ÁREA',
      source: 'PRINCIPAL'
    });


  }

  clearSelection(tipo: string, fromForm: boolean = false) {
    this.requiereOrden = false;
    this.requiereOrdenObligatorio = false;
    if (tipo == 'profesional') {
      this.profesionalCtrl.setValue('');
      this.especialidadCtrl.setValue('');
      this.centroAtencionCtrl.setValue('');
      this.servicioCtrl.setValue('');
      this.profesionalCtrl.enable();
      this.especialidadCtrl.disable();
      this.servicioCtrl.disable();
      this.centroAtencionCtrl.disable();
      this.profesionalSelected = null;
      this.especialidadSelected = null;
      this.servicioSelected = null;
      this.centroAtencionSelected = null;

      if (this.needLoadInitProfesionales && fromForm) {
        this.getProfesionales();
        this.needLoadInitProfesionales = false;
      }

    }

    if (tipo == 'especialidad') {
      this.centroAtencionCtrl.setValue('');
      this.servicioCtrl.setValue('');
      this.especialidadCtrl.setValue('');
      this.especialidadCtrl.enable();
      this.servicioCtrl.disable();
      this.centroAtencionCtrl.disable();
      this.especialidadSelected = null;
      this.servicioSelected = null;
      this.centroAtencionSelected = null;

      if (fromForm) {

        setTimeout(() => {
          try {
            this.triggerEspecialidad.openPanel();
          } catch (err) {
          }
        }, 500);

      }


      if (this.needLoadInitEspecialidades && fromForm) {
        this.getEspecialidades('especialidad');
        this.needLoadInitEspecialidades = false;
      }
    }

    if (tipo == 'servicio') {
      this.servicioCtrl.setValue('');
      this.centroAtencionCtrl.setValue('');
      this.servicioCtrl.enable();
      this.centroAtencionCtrl.disable();
      this.servicioSelected = null;

      setTimeout(() => {
        try {
          this.triggerServicios.openPanel();
        } catch (err) {
        }
      }, 500);

    }

    if (tipo == 'centros') {
      this.centroAtencionCtrl.setValue('');
      this.centroAtencionCtrl.enable();
      this.centroAtencionSelected = null;

      setTimeout(() => {
        try {
          this.triggerCentros.openPanel();
        } catch (err) {
        }
      }, 500);
    }

    this.emitBusqueda.emit({
      area: null,
      profesional: null,
      especialidad: null,
      centroAtencion: null
    })
  }

  priorizarCentro(data) {

    let centroPrioritario = ENV.idCentroPrioritario;
    let centros = [];
    let centroTodos = null;
    for (let centro of data) {
      if (centro['codigo'] !== 'todos') {
        if (centroPrioritario === centro['idCentro']) {
          centros.unshift(centro);
        } else {
          centros.push(centro);
        }
      } else {
        centroTodos = centro;
      }
    }

    if (data.length >= 2) {
      centros.push(centroTodos);
    }

    return centros;
  }

  organizarCentros(centros) {
    let arrCentros = [];
    const clSanCarlos = centros.find(item => item.detalle.toLowerCase().includes('san carlos'));
    const clTodos = centros.find(item => item.detalle.toLowerCase().includes('todos'));
    const centrosSinTodosSanCarlos: any[] = centros.filter(item => {
      const validSanCarlos = item.detalle.toLowerCase().includes('san carlos');
      const validTodos = item.detalle.toLowerCase().includes('todos')
      if (!validSanCarlos && !validTodos) {
        return item;
      }
    });

    if (clTodos) {
      arrCentros.push(clTodos);
    }

    if (clSanCarlos) {
      arrCentros.push(clSanCarlos);
    }

    const arr = arrCentros.concat(centrosSinTodosSanCarlos);

    return arr;
  }

  async getCentros(idServicio) {

    let isProf = (this.profesionalSelected) ? this.profesionalSelected['idProfesional'] : null;
    this.centrosAtencion = [];
    this.loadedCen = false;
    this.agendaService.getCentrosByEspecialidad(idServicio, this.areaSelected['id'], isProf, true).subscribe(res => {

      this.setDataQueryParams().then(async params => {

        let matCentro = null;
        let qp = params;

        if (qp['centro']) {
          this.hasQueryParams = true;
        }
        let region = (res['regiones'] && res['regiones'][0]) ? res['regiones'][0]['idRegion'] : null;

        if (!res['centros']) {
          res['centros'] = [];
        }

        res['centros'].forEach((val, key) => {
          ENV.idCentrosNoDisponibles.forEach((v, k) => {
            if (val['idCentro'] == v) {
              res['centros'].splice(key, 1);
            }
          });
        });

        let objTodos: any;

        if (res['centros'].length >= 1 || this.utils.slugify(this.areaSelected.nombre, "-") === 'telemedicina' || this.utils.slugify(this.areaSelected.nombre, "-") === 'consulta-medica-virtual') {
          objTodos = this.utils.getTodosLosCentros();
          res['centros'].unshift(objTodos);
        }

        if (res['centros'] && res['centros'].length > 0) {
          res['centros'].forEach((val, key) => {
            res['centros'][key]['detalle'] = val['detalle'] ? val['detalle'] : val['nombre'];
            if (qp['centro'] && qp['centro'].toLowerCase() == val['idCentro'].toString().toLowerCase()) {
              matCentro = res['centros'][key];
            }
          })

          res['centros'] = this.orderPipe.transform(res['centros'], 'detalle');
          res['centros'] = this.organizarCentros(res['centros']);
          this.centrosAtencion = this.utils.ordenarCentros(res['centros']);
          let porDerivacionUrl = false;

          if (matCentro) {
            this.centroAtencionCtrl.patchValue(matCentro);
            if (['RIS_IMAGENES', ENV.idAreaLaboratorio, ENV.idExamenProcedimiento].includes(this.areaSelected.id)) {
              await this.prepareFileS3();
              this.porDerivacionUrl = true;
              porDerivacionUrl = true;
            }

            this.centroAtencionSelection(matCentro, porDerivacionUrl);
            if (this.datosPaciente.documento && this.areaSelected.id !== 'RIS_IMAGENES' && !params) {
              this.buscarHora();
            } else {
              this.emitterReadQuery(true)
            }

          } else {

            if (res['centros'].length == 1 || (res['centros'].length > 1 && this.tipoConsulta === 'profesional')) {
              this.centroAtencionCtrl.patchValue(res['centros'][0]);
              this.centroAtencionSelection(res['centros'][0]);
            } else if (objTodos && (this.areaSelected.id === 'RIS_IMAGENES' || this.areaSelected.nombre.toLowerCase() === 'telemedicina')) {
              this.centroAtencionCtrl.patchValue(objTodos);
              this.centroAtencionSelection(objTodos);
            }

            this.emitterReadQuery(true)
          }

        } else {
          this.centrosAtencion = [];
          this.readQuery = true;
        }

        this.filterCentrosAtencion = this.centroAtencionCtrl.valueChanges.pipe(
          startWith<string | any>(''),
          map(value => typeof value === 'string' ? value : value.detalle),
          map(nombreFiltro => nombreFiltro ? this.filterAutocomplete(nombreFiltro, 'centros') : this.centrosAtencion.slice()),
        );

        this.loadedCen = true;
        setTimeout(() => {
          this.utils.hideProgressBar();
        }, 2500)
      })

    })

  }

  ocultarServicios(servicios, idCompare = 'id') {
    return servicios.filter(item => {
      const match = ENV.idOcultarServicios.find(itemOs => {
        return item[idCompare] === itemOs;
      });
      if (!match) {
        return item;
      }
    });
  }

  ocultarEspecialidades(especialidades, idCompare = 'id') {
    return especialidades.filter(item => {
      const match = ENV.idOcultarEspecialidades.find(itemOs => {
        return item[idCompare] === itemOs;
      });
      if (!match) {
        return item;
      }
    });
  }


  async getServicios() {

    const resServ = await this.agendaService.getServiciosByEspecialidad(this.especialidadSelected.idEspecialidad, this.areaSelected.id);
    const listServ = resServ && resServ['servicios'] ? resServ['servicios'] : [];
    this.servicios = (listServ && listServ.length > 0) ? listServ : [];
    this.servicios = this.ocultarServicios(this.servicios);

    this.setDataQueryParams().then(params => {

      let matServicio = null;
      let qp = params;

      if (this.servicios.length > 0) {

        this.servicios.forEach((val, key) => {
          this.servicios[key]['idServicio'] = val['id'];
          this.servicios[key]['nombreServicio'] = val['nombre'];
          this.servicios[key]['detalle'] = val['nombre'];
          if (qp['servicio'] && qp['servicio'].toLowerCase() == val['idServicio'].toLowerCase()) {
            matServicio = val;
          }
        })
        if (matServicio || this.servicios.length == 1) {
          let mServ = (this.servicios.length == 1) ? this.servicios[0] : matServicio;
          this.servicioCtrl.patchValue(mServ);
          this.servicioSelection(mServ);
        } else {
          this.emitterReadQuery(true)
        }


      } else {
        this.readQuery = true;
      }

      this.servicios = this.transformarRepuestaGeneral(this.servicios);

      this.filterServicios = this.servicioCtrl.valueChanges.pipe(
        startWith<string | any>(''),
        map(value => typeof value === 'string' ? value : value.detalle),
        map(nombreFiltro => nombreFiltro ? this.filterAutocomplete(nombreFiltro, 'servicios') : this.servicios.slice()),
      );

      this.loadedServ = true;

    });

  }

  transformarRepuestaGeneral(servicios) {

    const arrWithGeneral = [];
    const arrWithoutGeneral = [];

    servicios.forEach((val, key) => {
      if (val.nombre.toLowerCase().includes('general')) {
        arrWithGeneral.push(val);
      } else {
        arrWithoutGeneral.push(val);
      }
    });

    const orderedArrWithGeneral = this.orderPipe.transform(arrWithGeneral, 'nombre');
    const orderedArrWithoutGeneral = this.orderPipe.transform(arrWithoutGeneral, 'nombre');

    return orderedArrWithGeneral.concat(orderedArrWithoutGeneral);

  }

  especialidadSelection(event, tipo = null) {
    if (event.option && !event.option.value) {
      return false;
    }
    this.requiereOrden = false;
    this.requiereOrdenObligatorio = false;
    this.especialidadCtrl.disable();

    this.especialidadSelected = this.especialidadCtrl.value;

    if (tipo == 'especialidad') {
      this.getServicios();
      this.servicioCtrl.enable();
    } else {
      this.centroAtencionCtrl.enable();
      this.getCentros(this.especialidadCtrl.value.idServicio);
    }

    gtag('event', 'Filtro de Búsqueda', { 'event_category': 'Seleccionar Especialidad', 'event_label': this.especialidadSelected.detalle, 'value': '0' });

    this.agendaService.crearMarca({
      dni: null,
      dni_type: null,
      element: 'COMBO - SELECCIONAR ESPECIALIDAD',
      value: this.especialidadSelected.detalle.toUpperCase(),
      detail: 'SELECCIONAR ESPECIALIDAD',
      source: 'PRINCIPAL'
    });
  }

  servicioSelection(event) {
    this.respuestasEncuestas = [];
    this.requiereOrden = false;
    this.requiereOrdenObligatorio = false;
    this.servicioCtrl.disable();
    this.servicioSelected = this.servicioCtrl.value;
    this.centroAtencionCtrl.enable();
    gtag('event', 'Filtro de Búsqueda', { 'event_category': 'Seleccionar Servicio', 'event_label': this.servicioSelected.nombreServicio, 'value': '0' });

    this.agendaService.crearMarca({
      dni: null,
      dni_type: null,
      element: 'COMBO - SELECCIONAR SERVICIO',
      value: this.servicioSelected.nombreServicio.toUpperCase(),
      detail: 'SELECCIONAR SERVICIO',
      source: 'PRINCIPAL'
    });

    this.getCentros(this.servicioCtrl.value.idServicio);

  }


  profesionalSelection(event) {
    this.requiereOrden = false;
    this.requiereOrdenObligatorio = false;
    this.profesionalCtrl.disable();
    this.profesionalSelected = this.profesionalCtrl.value;
    this.especialidadCtrl.enable();
    gtag('event', 'Filtro de Búsqueda', { 'event_category': 'Seleccionar Profesional', 'event_label': this.profesionalSelected.detalle, 'value': '0' });

    this.agendaService.crearMarca({
      dni: null,
      dni_type: null,
      element: 'COMBO - SELECCIONAR PROFESIONAL',
      value: this.profesionalSelected.detalle.toUpperCase(),
      detail: 'SELECCIONAR PROFESIONAL',
      source: 'PRINCIPAL'
    });


    this.getEspecialidades('profesional');
  }

  async centroAtencionSelection(event, porDerivacionUrl = false) {
    this.requiereOrden = false;
    this.requiereOrdenObligatorio = false;
    this.porDerivacionUrl = porDerivacionUrl;
    this.centroAtencionCtrl.disable();
    this.centroAtencionSelected = this.centroAtencionCtrl.value;
    gtag('event', 'Filtro de Búsqueda', { 'event_category': 'Seleccionar Centro', 'event_label': this.centroAtencionSelected.detalle, 'value': '0' });

    this.agendaService.crearMarca({
      dni: null,
      dni_type: null,
      element: 'COMBO - SELECCIONAR CENTRO',
      value: this.centroAtencionSelected.detalle.toUpperCase(),
      detail: 'SELECCIONAR CENTRO',
      source: 'PRINCIPAL'
    });

    if (this.areaSelected.id !== 'RIS_IMAGENES') {
      await this.validarRequiereOrden();
    }

    if (this.hasQueryParams) {
      this.buscarHora();
    }
  }

  async buscarHora(fromBtn = false) {

    const dateTm: any = this.utils.trDateStr(new Date(), null, -240);
    const dateTime = dateTm.split("-04:00").join("");

    if (this.tipoConsulta === 'profesional' && !this.profesionalSelected) {
      this.utils.mDialog('Error', 'Debe seleccionar el Profesional con quien se desea atender.', 'message');
      return false;
    }

    if (!this.especialidadSelected) {
      this.utils.mDialog('Error', 'Debe seleccionar una Especialidad Médica.', 'message');
      return false;
    }
    if (!this.servicioSelected && this.tipoConsulta === 'especialidad') {
      this.utils.mDialog('Error', 'Debe seleccionar una Área de Interés.', 'message');
      return false;
    }

    if (!this.centroAtencionSelected && this.areaSelected.nombre.toLowerCase() !== 'telemedicina') {
      this.utils.mDialog('Error', 'Debe seleccionar un Centro de Atención.', 'message');
      return false;
    }

    if (!this.datosPaciente.documento && fromBtn) {
      this.utils.mDialog('Error', 'Debe indicar su identificación.', 'message');
      return false;
    }

    if ((this.datosPaciente.tipoDocumento === 'RUN' && !this.utils.validateRun(this.datosPaciente.documento)) && fromBtn) {
      this.utils.mDialog('Error', 'El formato del RUN es incorrecto. Verifique e intente nuevamente', 'message');
      return false;
    }

    if (this.tipoConsulta == 'especialidad') {
      this.especialidadSelected['idServicio'] = this.servicioSelected['idServicio'];
      this.especialidadSelected['nombreServicio'] = this.servicioSelected['nombreServicio'];
    }

    let continueEncuesta: any = true;
    let aplicaEncuesta = false;
    let respuestasEncuestas = [];
    let idEncuesta = null;

    try {

      if (!this.datasUpload.length && this.areaSelected.id === 'RIS_IMAGENES') {
        this.utils.mDialog("Error", "Debe adjuntar orden médica.", "message");
        return;
      }

      if (ENV.areasConEncuestas.includes(this.areaSelected.id) || !ENV.areasConEncuestas.length) {

        const respEnc: any = await this.prepareEncuestaPorArea();

        aplicaEncuesta = respEnc && respEnc.encuesta && respEnc.encuesta.length > 0;
        
        if (aplicaEncuesta && this.datosPaciente.documento) {

          idEncuesta = respEnc.idEncuesta;
          respEnc.isBancoSangre = this.areaSelected.id === ENV.donacionBancoDeSangre.idArea;
          const noTieneRespuestas =  this.respuestasEncuestas.length === 0;
          const actionConRespuestas = { action: true , respuestas: this.respuestasEncuestas };
          const dataEncuesta = { ...this.datosPaciente, ...respEnc, areaId: this.areaSelected.id, servicioId: this.servicioSelected.id }
          const ressp: any = noTieneRespuestas ? await this.mostrarEncuesta(dataEncuesta) : actionConRespuestas;
          continueEncuesta = ressp.action;
          respuestasEncuestas = ressp.respuestas;
          this.respuestasEncuestas = ressp.respuestas;

          gtag('event', 'Filtro de Búsqueda', { 'event_category': 'Encuesta Médica', 'event_label': 'Completar Encuesta', 'value': '0' });

          this.agendaService.crearMarca({
            dni: this.datosPaciente.documento,
            dni_type: this.datosPaciente.tipoDocumento,
            element: 'COMPLETAR ENCUESTA MÉDICA',
            value: String(ressp.idRespuesta),
            detail: 'SE COMPLETA ENCUESTA MÉDICA',
            source: 'PRINCIPAL',
            into: "etapas"
          });

        }

        gtag('event', 'Filtro de Búsqueda', { 'event_category': 'Adjuntar Orden', 'event_label': 'Ingresar Órden Médica', 'value': '0' });

        this.agendaService.crearMarca({
          dni: this.datosPaciente.documento,
          dni_type: this.datosPaciente.tipoDocumento,
          element: 'BOTÓN ADJUNTA ORDEN MÉDICA',
          value: 'SE ADJUNTA ORDEN MÉDICA',
          detail: 'SE ADJUNTA ORDEN MÉDICA',
          source: 'PRINCIPAL'
        });

      }

    } catch (err) {
      console.log(err)
      continueEncuesta = false;
    }

    if (!continueEncuesta || aplicaEncuesta && !this.datosPaciente.documento) {
      return;
    }

    if (this.requiereOrden && this.requiereOrdenObligatorio && !this.datasUpload.length) {
      this.utils.mDialog("Error", "Debe adjuntar mínimo una orden médica de atención", "message");
      return;
    }

    if(this.requiereOrden){
      this.agendaService.crearMarca({
        dni: this.datosPaciente.documento,
        dni_type: this.datosPaciente.tipoDocumento,
        element: 'SERVICIO REQUIERE ORDEN',
        value: JSON.stringify(this.datosPaciente),
        detail: 'SE CONSUME SERVICIO PARA VALIDAR QUE SERVICIO REQUIERE ORDEN',
        source: 'PRINCIPAL',
        into: "etapas"
      });
    }

    await this.getDatosPaciente();

    if (this.tipoConsulta === 'especialidad') {
      gtag('config', ENV.analyticsCode,
        { 'page_path': `/busqueda/${this.tipoConsulta}/area/${this.areaSelected.id}/servicio/${this.servicioCtrl.value.idServicio}/centro/${this.centroAtencionCtrl.value.idCentro}` });
    } else {
      gtag('config', ENV.analyticsCode,
        { 'page_path': `/busqueda/${this.tipoConsulta}/area/${this.areaSelected.id}/profesional/${this.profesionalSelected.idProfesional}/servicio/${this.especialidadSelected.idServicio}/centro/${this.centroAtencionCtrl.value.idCentro}` });
    }

    gtag('event', 'Filtro de Búsqueda', { 'event_category': 'Ingresar RUT', 'event_label': this.datosPaciente.documento, 'value': '0' });
    gtag('event', 'Filtro de Búsqueda', { 'event_category': 'Buscar Hora', 'event_label': 'Búsqueda Completa', 'value': '0' });

    this.agendaService.crearMarca({
      dni: this.datosPaciente.documento,
      dni_type: this.datosPaciente.tipoDocumento,
      element: 'INGRESO RUT / PASAPORTE',
      value: this.datosPaciente.documento,
      detail: 'SE INGRESA EL RUT / PASAPORTE DEL PACIENTE',
      source: 'PRINCIPAL',
      into:'etapas'
    });

    this.agendaService.crearMarca({
      dni: this.datosPaciente.documento,
      dni_type: this.datosPaciente.tipoDocumento,
      element: 'BUSCAR HORA',
      value: this.datosPaciente.documento,
      detail: 'SE REALIZA LA BÚSQUEDA DE LA HORA',
      source: 'PRINCIPAL',
      into:'etapas'
    })

    this.datosImagenes.requiereOrden = this.requiereOrden;
    this.datosImagenes.requiereOrdenObligatorio = this.requiereOrdenObligatorio;
    this.datosImagenes.listadoArchivos = this.datasUpload;
    this.datosImagenes.tokenRequiereOrden = this.tokenRequiereOrden;
    
    if( aplicaEncuesta ){
      const data = {
        idEncuesta: String(idEncuesta),
        idPaciente: this.datosPaciente.documento,
        respuestas: respuestasEncuestas
      }
      const respuestaEncuesta:any = await this.ejecutarEncuesta(data);
      if(respuestaEncuesta.action){
        this.datosImagenes.idEncuesta = respuestaEncuesta.idRespuesta;
      }else{
        return;
      }
    }
    
    this.emitBusqueda.emit({
      tipoConsulta: this.tipoConsulta,
      area: this.areaSelected,
      profesional: this.profesionalSelected,
      especialidad: this.especialidadSelected,
      centroAtencion: this.centroAtencionSelected,
      documentoPaciente: this.datosPaciente,
      centrosDisponibles: [],
      datosImagenes: this.datosImagenes
    })

    this.emitterReadQuery(true);

  }

  ejecutarEncuesta(data){

    return new Promise( async resolve  => {

      const isBancoSangre = ENV.donacionBancoDeSangre.idArea === this.areaSelected.id;

      const resp: any = await this.agendaService.postEncuesta(data);
      const esPrecaucion = resp.resultado && resp.resultado.toUpperCase().includes('PRECAUC');
      const esPositivo = resp.resultado && resp.resultado.toUpperCase() === 'POSITIVO';
      const esNegativo = resp.resultado.toUpperCase() === 'NEGATIVO';
      const continuaEncuesta = esPositivo || esPrecaucion ? true : false;

      let mensajes = '';
      if(resp.mensaje){
        resp.mensaje.forEach((val, key) => {
          mensajes += `<p>${val}</p>`;
        });
      }else{
        mensajes =  `<p>En estos momentos no se puede evaluar la encuesta. Intente de nuevo más tarde.</p>`;
        console.error("API DE EVALUACIÓN DE ENCUESTA NO TRAJO MENSAJES PARA MOSTRAR.");
      }

      resolve({ action: continuaEncuesta, idRespuesta : continuaEncuesta ? resp.id : null });

      if(esNegativo || esPrecaucion){
        this.utils.mDialog(isBancoSangre ? "Estimado Donante" : "Estimado Paciente", mensajes, 'message');
      }

    })


  }

  async prepareEncuestaPorArea() {
    try{
      const idEspecialidad = this.servicioSelected ? this.servicioSelected.idEspecialidad : this.especialidadSelected.idEspecialidad;
      const idServicio = this.servicioSelected ? this.servicioSelected.id : this.especialidadSelected.idServicio;
      if (this.areaSelected.id === 'RIS_IMAGENES') {
        const codCentro = this.centroAtencionSelected.codigo && this.centroAtencionSelected.codigo === 'todos' ? null : this.centroAtencionSelected.idCentro;
        return await this.agendaService.getEncuesta(idServicio, codCentro, this.datosImagenes.aplicaMedioContraste);
      } else {
        return await this.agendaService.getEncuestaGenerica(this.areaSelected.id, idEspecialidad, idServicio);
      }
    }catch(err){
      console.error(err);
      return Promise.resolve(null);
    }
  }

  getDatosPaciente() {
    return new Promise((resolve, reject) => {

      try {
        const tipoDocumento = this.datosPaciente.tipoDocumento;
        const documento = this.datosPaciente.documento;
        this.agendaService.getPaciente(documento, tipoDocumento, this.areaSelected.id).subscribe((res: any) => {
          if (res && res.listaPacientes && res.listaPacientes.length > 0) {
            this.datosPaciente.idPaciente = res.listaPacientes[0].id;
            this.datosPaciente.paciente = res.listaPacientes[0];
          } else {
            this.datosPaciente.paciente = null;
            this.datosPaciente.idPaciente = null;
          }
          resolve(true);
        }, () => {
          this.datosPaciente.paciente = null;
          this.datosPaciente.idPaciente = null;
          resolve(true);
        });

      } catch (err) {
        resolve(true);
      }
    });

  }

  buscarProximaHora(data) {
    data['fromCuposInmediatos'] = true;
    data['documentoPaciente'] = {
      tipoDocumento: 'RUN',
      documento: null,
      documentoFormateado: null
    }
    gtag('event', 'Horas Disponibles', { 'event_category': 'Cupo Inmediato', 'event_label': `Cupo Especialidad`, 'value': '0' });

    this.agendaService.crearMarca({
      dni: null,
      dni_type: null,
      element: 'BOTÓN CUPOS INMEDIADOS',
      value: JSON.stringify(data),
      detail: 'PACIENTE BUSCA POR CUPOS INMEDIATOS',
      source: 'CUPOS INMEDIATOS',
      into: "etapas"
    });

    this.emitBusqueda.emit(data);

    if (!data.profesional) {
      gtag('config', ENV.analyticsCode,
        { 'page_path': `/busqueda/cuposinmediatos/area/${data.area.id}/servicio/${data.especialidad.idServicio}/centro/${data.centroAtencion.idCentro}` });
    } else {
      gtag('config', ENV.analyticsCode,
        { 'page_path': `/busqueda/cuposinmediatos/area/${data.area.id}/profesional/${data.profesional.idProfesional}/servicio/${data.especialidad.idServicio}/centro/${data.centroAtencion.idCentro}` });
    }
  }

  emitterReadQuery(status) {
    this.readQuery = status;
    if (status) {
      this.emitReadQuery.emit(status)
    }
  }

  async setDataQueryParams() {
    return new Promise((resolve, reject) => {
      let p = {};
      this.aRouter.queryParams.subscribe(params => {
        if (!this.readQuery) {
          p = params;
        }
        resolve(p);
      });
    })

  }

  cambiarTipoBusqueda(tipo) {

    this.clearSelection('profesional');
    if (tipo == 'especialidad') {
      this.tipoConsulta = 'especialidad';
      this.filterEspecialidades = null;
      this.filterServicios = null;
      this.filterCentrosAtencion = null;
      this.getEspecialidades('especialidad')
    }

    if (tipo == 'profesional') {
      this.tipoConsulta = 'profesional';
      this.loadedProf = true;
      this.filterEspecialidades = null;
      this.filterCentrosAtencion = null;
      this.valueChangeProfesional();
      //this.getProfesionales();
    }
  }

  setFormatRut() {
    this.datosPaciente.documentoFormateado = (this.datosPaciente.documentoFormateado) ?
      this.datosPaciente.documentoFormateado.trim() : null;

    if (this.datosPaciente.tipoDocumento == 'RUN') {
      let rut = this.datosPaciente.documentoFormateado;
      if (rut && rut != "") {
        let rutPuntos = this.utils.formatRut(rut)
        this.datosPaciente.documentoFormateado = rutPuntos
        this.datosPaciente.documento = this.utils.replaceAll(rutPuntos, ".", "");
      }
    } else {
      this.datosPaciente.documento = this.datosPaciente.documentoFormateado;
    }

  }

  restoreFormatRut() {
    if (this.datosPaciente.documentoFormateado && this.datosPaciente.documentoFormateado != "" && this.datosPaciente.tipoDocumento == 'RUN') {
      let documento = this.datosPaciente.documentoFormateado.trim();
      documento = this.utils.replaceAll(documento, ".", "");
      documento = this.utils.replaceAll(documento, "-", "");
      this.datosPaciente.documentoFormateado = documento;
    }
  }


  cambiarTipoIdentificacion(e) {
    this.datosPaciente = {
      tipoDocumento: e,
      documento: null,
      documentoFormateado: null
    }
  }

  mostrarEncuesta(respEnc) {
    return new Promise((resolve, reject) => {
      const dialogConfirm = this.dialog.open(EncuestasComponent, {
        width: '840px',
        autoFocus: false,
        disableClose: true,
        data: respEnc,
      });

      dialogConfirm.componentInstance.dialogEvent.subscribe(res => {
        resolve(res);
      });
    });
  }


  resetInputFile() {
    let input = document.getElementById("ordenmedica");
    input['value'] = "";
  }

  openInputFile() {
    document.getElementById("ordenmedica").click();
  }

  async fileChange(files: File[]) {

    try {

      const totalArchivos = Object.keys(files).length + this.datasUpload.length;

      if (totalArchivos > this.cantidadMaximaOrdenes) {
        this.utils.mDialog("Error", `Solo puede ajuntar un total de ${this.cantidadMaximaOrdenes} de órdenes médicas`, "message");
        this.resetInputFile();
        return;
      }

      for await (let a of Object.keys(files)) {
        const filesArr = await this.utils.prepareFile(files[a], true);
        this.datasUpload = this.datasUpload.concat(filesArr)
      }
      this.resetInputFile();

    } catch (err) {

      return;

    }

  }

  borrarArchivo(i) {
    this.utils.mDialog("Notificación", "¿Está seguro que desea remover la orden médica cargada?", "confirm").subscribe(res => {
      if (res) {
        this.datasUpload.splice(i, 1);
      }
    })
  }

  async prepareFileS3() {
    const params: any = await this.setDataQueryParams();
    const files = [];
    let loginResponse;
    let token;

    if (!params.idDocumento) {
      return
    }

    const documentos = params.idDocumento.split(",");
    const ruta = params.ruta ? params.ruta.toUpperCase() : null;

    if (ruta !== 'INDICACIONESSF') {
      loginResponse = await this.agendaService.loginUsuario();
      token = loginResponse.data.token;
    }

    for (const doc of documentos) {
      try {
        const [name, ext] = doc.split(".");
        const base64 = ruta !== 'INDICACIONESSF' ? await this.agendaService.obtenerObjetoBase64(doc, token) : await this.agendaService.getFileFromSF(doc);
        files.push(this.getFileStructure(doc, base64, ext));
      } catch (err) {
        console.error(err);
      }
    }

    this.datasUpload = files;
  }

  getFileStructure(doc, base64, ext) {
    return {
      displayName: doc,
      file: base64,
      file64: base64,
      mimetype: this.utils.getMymetypeByExtension(ext),
      name: doc,
      size: "0KB"
    }
  }
  async validarRequiereOrden() {
    try {
      const idServicio = this.tipoConsulta === 'profesional' ? this.especialidadSelected.idServicio : this.servicioSelected.idServicio
      const data = {
        idArea: this.areaSelected.id,
        idServicio: idServicio,
        idCentro: this.centroAtencionSelected.idCentro
      };
      const response: any = await this.agendaService.validarRequiereOrden(data);
      this.requiereOrden = response && response.requiereOrden;
      this.requiereOrdenObligatorio = response && response.tipo === 'OBLIGATORIO';
      this.tokenRequiereOrden = response && response.token ? response.token : "";
      console.log(this.requiereOrdenObligatorio)
    } catch (err) {
      this.requiereOrden = false;
      this.requiereOrdenObligatorio = false;
      console.log(err);
    }
  }

  get bloqueoDatosPaciente(){
    try{
      const idAreaSelected = this.areaSelected.id;
      const idServicioSelected = this.tipoConsulta === 'profesional' ? this.especialidadSelected.idServicio : this.servicioSelected.idServicio ;
      const { idArea , idServicio, bloqueoActivo } = BLOQUEO_LABORATORIO_CLINICO;
      return bloqueoActivo && idAreaSelected === idArea && idServicioSelected === idServicio;  
    }catch(err){
      return false;
    }
  }

}